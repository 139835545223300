// Override Bootstrap color system
$primary-1 : #011C26; 
$primary-2 : #103038; 
$secondary-3 : #BF1111; 
$secondary-4 : #8C1C1C; 
$white-col-5-hex : #F2F2F2; 

// Pre-state grayscale colors used in other variables
$gray-100:$white-col-5-hex;
$gray-800: $primary-1 ;
$gray-600: $primary-2;

// Override Bootstrap default state colors
$primary: $primary-1;
$secondary: $secondary-4;

$body-bg:$gray-100;
$body-color:$gray-600;
$link-hover-color:$secondary;
// Override Bootstrap yiq lightness value

$yiq-contrasted-threshold: 205;

//Antigo
// --gray-color-one: #F5F5F5;
//     --gray-color-two: #9C9C9C;
//     --gray-color-tree: #636363;
//     --gray-color-four: #292929;
//     --secondary-color-one: #0487D9;
//     --secondary-color-two: #0420BF;
//     --primary-color-one: #0ABF04;
//     --primary-color-two: #078C03;
//     --primary-color-three: #034001;