// Global styling for this template

body {
    padding-top: $navbar-base-height;
    color: $gray-600;
}

@include media-breakpoint-up(lg) {
    body {
        padding-top: 0;
        padding-left: $sidebar-base-width;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    margin-bottom: 0;
    color: $body-color;
}

h1 {
    font-size: 6rem;
    line-height: 5.5rem;
}

p.lead {
    font-size: 1.15rem;
    font-weight: 400;
}

.subheading {
    display: flex;
    font-family: $headings-font-family;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.5rem;

    *{
        display: flex;
        align-items: center;
        margin-right: 1rem;
        *{
            margin-right: 0.5rem;
        }
    }
}