// Override Bootstrap typography variables
@import url('https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

$font-family-base: 'Roboto', sans-serif;

$headings-font-family: 'Saira Extra Condensed', sans-serif;

$headings-font-weight: 700;

$headings-color: $gray-800;

$h1-font-size: 5rem;
$h2-font-size: 3rem;
$h3-font-size: 2.5rem;
