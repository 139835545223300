form {
    .form-control {
        margin-bottom: 0.5rem;
        
    }

    label {
        margin: 0.6rem 0 0.5rem;
    }

    .submit-button {
        letter-spacing: 0.1rem;
    }

}