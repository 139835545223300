section.resume-section {
    display: flex;
    align-items: center;
    padding: 5rem 1rem ;
    max-width: 75rem;
    .resume-section-content {
        width: 100%;
    }
}
@include media-breakpoint-up(md) {
    section.resume-section {
        min-height: 100vh;
    }
}
@include media-breakpoint-up(lg) {
    section.resume-section {
        padding-left: 5rem 3rem;
    }
}
