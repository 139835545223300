// Import variables
@import "./_variables.scss";

// Import Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

// Global CSS
@import "./_global.scss";

// Components
@import "./components/_sidenav.scss";
@import "./components/_icons.scss";
@import "./components/_forms.scss";

// Sections
@import "./sections/_resume-section.scss";
